<template>
  <div class="uploadimgs">
    <div class="wx-upload">
      <span>微信收款码上传</span>
      <uploader 
        style="margin-top:10px" 
        multiple
        :max-count="50"
        v-model="fileList" 
        :after-read="afterRead" 
      />
    </div>
    <div class="upbtn">
      <Button type="primary" block>立即上传</Button>
    </div>
  </div>
</template>
<script>
import { Uploader, Button  } from 'vant';
import { UpLoadImges } from '@/api/uploadImgs'
export default {
  components: {
    Uploader, Button
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    afterRead(file) {
      var imgsArr = [];
      for (let i = 0; i < file.length; i++) {
        imgsArr.push(file[i].file)
      }
      // 上传文件
      UpLoadImges(imgsArr)
      // file.status = 'uploading';
      // file.message = '上传中...';
      // setTimeout(() => {
      //   file.status = 'failed';
      //   file.message = '上传失败';
      // }, 1000);
    }
  }
}
</script>
<style lang="scss" scoped>
.uploadimgs {
  height: 100vh;
  width: 100%;
  padding-top: 10px;
  background: #f2f2f2;
}
.wx-upload, .alipay-upload {
  width: 98%;
  min-height: 40vh;
  display: flex;
  padding: 6px;
  margin: 5px auto 0;
  border-radius: 10px;
  background: #ffffff;
  flex-direction: column;
  span {
    display: block;
    border-bottom: 1px solid #ddffdd;
    line-height: 30px;
    font-size: 14px;
    color: #696969;
  }
}
.upbtn {
  width: 96%;
  margin: 10px auto;
}
</style>