import axios from "axios";
import { Toast } from "vant";
const url = "https://www.58zltc.com/api/config.single_wechat/add";
const formData = new FormData();
formData.append('token', sessionStorage.getItem('token'));
formData.append('app_id', sessionStorage.getItem('app_id'));

function UpLoadImges(imgArr) {
  formData.append('image', imgArr);
	axios
		.post(url, formData, {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			}
		})
		.then(function(response) {
      var result = response.data;
      if (result.code == 0) {
        Toast(result.msg)
      }
		})
		.catch(function(error) {
      console.log(error);
		});
}
export { UpLoadImges };
